/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.4 routes of Headcount::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /admin/subscriptions/:id/add_entry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_entry_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_entry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/add_feedlot(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_feedlot_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_feedlot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/add_job(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_job_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_job"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calendar_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_calendar_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendar_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calendar_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_calendar_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendar_events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/count_things_trackers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_count_things_tracker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"count_things_trackers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/count_things_trackers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_count_things_trackers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"count_things_trackers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/counters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_counter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counter_audits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_counter_audit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counter_audits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counter_audits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_counter_audits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counter_audits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/counters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counters"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_feedlots_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/flight_plan_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_flight_plan_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"flight_plan_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/flight_plan_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_flight_plan_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"flight_plan_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pay_stub_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pay_stubs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pens_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tools/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tools"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/work_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_work_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"work_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/work_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_work_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"work_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/feedlots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_feedlot_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/feedlots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_feedlots_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"feedlots"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_job_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_jobs_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_pen_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_pens_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/archive_flight_plan(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_flight_plan_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive_flight_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/audit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const audit_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"audit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters/audits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const audits_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"audits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/masquerade/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_user_masquerade_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters/change_needs_reflown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_needs_reflown_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"change_needs_reflown"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters/change_needs_reviewed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_needs_reviewed_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"change_needs_reviewed"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/change_password(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/comment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"comment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/confirm_user(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_user_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tools/convert_micro_csv_as_geojson(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_micro_csv_as_geojson_admin_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tools"],[2,[7,"/"],[2,[6,"convert_micro_csv_as_geojson"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const counter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/counties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const counties_admin_feedlots_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[6,"counties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/create_feedlot(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_feedlot_admin_feedlots_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[6,"create_feedlot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/create_job(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_job_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_job"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/create_line_item(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_line_item_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_line_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/create_pen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_pen_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_pen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/create_stub(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_stub_admin_pay_stubs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[6,"create_stub"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/create_take_off_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_take_off_location_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_take_off_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/data_for_image_import(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_for_image_import_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"data_for_image_import"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shares/:id/delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_share_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/delete_share(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_share_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"delete_share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/delete_take_off_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_take_off_location_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_take_off_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/duplicate_plan_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_plan_request_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate_plan_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_admin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calendar_events/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_calendar_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendar_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/count_things_trackers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_count_things_tracker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"count_things_trackers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_counter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counter_audits/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_counter_audit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counter_audits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/flight_plan_requests/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_flight_plan_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"flight_plan_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_pay_stub_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tools/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/work_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_work_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"work_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/feedlots/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_feedlot_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_job_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v1_pen_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_billing_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_name_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_billing_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/edit_compensation_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_compensation_settings_admin_pay_stubs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[6,"edit_compensation_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_counter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_counter_level(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_counter_level_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_counter_level"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/edit_detections(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_detections_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_detections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_discord_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_discord_name_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_discord_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/edit_entry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_entry_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_entry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/edit_kham_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_kham_data_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_kham_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/edit_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_name_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/edit_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_name_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_onboarding_mode(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_onboarding_mode_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_onboarding_mode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/edit_passes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_passes_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_passes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/edit_pen_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_pen_name_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_pen_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit_policies_accepted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_policies_accepted_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_policies_accepted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/edit_ref_pen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ref_pen_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_ref_pen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/edit_ref_pen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ref_pen_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_ref_pen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/edit_ref_pen_name(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ref_pen_name_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_ref_pen_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shares/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_share_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/edit_share(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_share_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit_share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/edit_skip_quality_control(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_skip_quality_control_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_skip_quality_control"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/edit_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_status_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/edit_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_status_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/edit_yard_count(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_yard_count_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_yard_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/export_csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_csv_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/export_excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_excel_admin_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"export_excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/export_ref_pens(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_ref_pens_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_ref_pens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /feedlots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feedlots_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/from_micro_id(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const from_micro_id_api_v1_pens_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[6,"from_micro_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/generate_excel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_excel_admin_pay_stubs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[6,"generate_excel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/generate_path(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_path_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_path"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/generate_picture(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_picture_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_picture"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/generate_picture_boundary_difference(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_picture_boundary_difference_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_picture_boundary_difference"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/generate_picture_boundary_difference(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_picture_boundary_difference_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"generate_picture_boundary_difference"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/generate_picture_snippet(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_picture_snippet_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_picture_snippet"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/generate_picture_snippet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_picture_snippet_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"generate_picture_snippet"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/generate_training_picture(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_training_picture_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_training_picture"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/get_ref_pen_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_ref_pen_data_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_ref_pen_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/heart_beat(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heart_beat_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"heart_beat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/heart_beat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heart_beat_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"heart_beat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/accept_policies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_accept_policies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"accept_policies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/change_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_change_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"change_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/edit_name_and_number(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_edit_name_and_number_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"edit_name_and_number"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/edit_notification_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_edit_notification_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"edit_notification_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/privacy_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_privacy_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_terms_of_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/viewed_tutorial_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_viewed_tutorial_step_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"viewed_tutorial_step"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/image_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const image_data_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"image_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/image_locations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const image_locations_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"image_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/import_csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_csv_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"import_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/import_micro_pen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_micro_pen_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"import_micro_pen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/import_picture(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_picture_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"import_picture"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/import_ref_pens(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_ref_pens_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"import_ref_pens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/invite_team_member(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_team_member_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite_team_member"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/feedlots/:id/link(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const link_api_v1_feedlot_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs/:id/link(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const link_api_v1_job_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/:id/link(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const link_api_v1_pen_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/link_from_micro_id(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const link_from_micro_id_api_v1_pens_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[6,"link_from_micro_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/map(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/map(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"map"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calendar_events/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_calendar_event_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calendar_events"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/count_things_trackers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_count_things_tracker_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"count_things_trackers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_counter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counter_audits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_counter_audit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counter_audits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_feedlot_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/flight_plan_requests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_flight_plan_request_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"flight_plan_requests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_job_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_pay_stub_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_pen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tools/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_tool_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tools"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/work_orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_work_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"work_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/feedlots/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_feedlot_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_job_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v1_pen_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_counter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_feedlot_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /jobs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_job_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pens/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_pen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shares/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_share_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/next(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const next_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"next"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/next(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const next_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"next"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/notify_flight_plan_ready(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notify_flight_plan_ready_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notify_flight_plan_ready"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id/original_picture(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const original_picture_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"original_picture"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/pay_stubs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pay_stubs_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"pay_stubs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/pen_boundaries(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pen_boundaries_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pen_boundaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/pen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pen_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"pen"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/pen_locations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pen_locations_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pen_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pens_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pens"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /counters/pens_to_count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pens_to_count_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"pens_to_count"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pghero
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_path = __jsr.r({}, [2,[7,"/"],[6,"pghero"]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_space_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space/:relation(.:format)
 * @param {any} relation
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_relation_space_path = __jsr.r({"database":{},"relation":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[2,[7,"/"],[2,[3,"relation"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/index_bloat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_index_bloat_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"index_bloat"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/live_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_live_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"live_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries/:query_hash(.:format)
 * @param {any} query_hash
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_show_query_path = __jsr.r({"database":{},"query_hash":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[2,[7,"/"],[2,[3,"query_hash"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/cpu_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_cpu_usage_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"cpu_usage"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connection_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connection_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connection_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/replication_lag_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_replication_lag_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"replication_lag_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/load_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_load_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"load_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/free_space_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_free_space_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"free_space_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/explain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_explain_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"explain"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/tune(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_tune_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"tune"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connections_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connections"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/maintenance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_maintenance_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"maintenance"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_long_running_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_long_running_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_long_running_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_all_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_all"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/enable_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_enable_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"enable_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/reset_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_reset_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"reset_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero/(:database)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_root_path = __jsr.r({"database":{},"format":{}}, [2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[2,[1,[3,"database"]],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/photos_uploaded(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos_uploaded_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"photos_uploaded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/photos_uploaded_started(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos_uploaded_started_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"photos_uploaded_started"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/:id/picture(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const picture_api_v1_pen_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"picture"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/pens/picture_from_micro_id(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const picture_from_micro_id_api_v1_pens_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[6,"picture_from_micro_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/possibleRefPens(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const possibleRefPens_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"possibleRefPens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_incinerate_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/recount(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recount_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"recount"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /registrations/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const registrations_confirm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"registrations"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/remove_entry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_entry_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_entry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/remove_feedlot(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_feedlot_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_feedlot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/remove_job(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_job_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_job"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/remove_line_item(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_line_item_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_line_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/remove_team_member(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_team_member_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_team_member"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/renew(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const renew_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"renew"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs/:id/request_flight_plan(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_flight_plan_api_v1_job_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_flight_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/reset_passes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_passes_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_passes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/reset_training(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_training_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"reset_training"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /admin/work_orders/:id/send_to_portal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_portal_admin_work_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"work_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_portal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/counter_audits/:id/set_counter_access(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_counter_access_admin_counter_audit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"counter_audits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_counter_access"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feedlots/:id/set_dronelink_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_dronelink_email_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_dronelink_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/set_ignore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_ignore_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_ignore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/set_is_micro(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_is_micro_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_is_micro"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/set_priority(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_priority_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_priority"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/set_should_auto_complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_should_auto_complete_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_should_auto_complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/set_skip_billing(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_skip_billing_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_skip_billing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/set_skip_sub_entry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_skip_sub_entry_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_skip_sub_entry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shares/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shares(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shares_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/stats(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/stats(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"stats"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters/submit_pen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submit_pen_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"submit_pen"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters/submit_training(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submit_training_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"submit_training"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/take_off_locations(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const take_off_locations_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"take_off_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/training(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const training_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"training"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /counters/training(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const training_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"training"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /counters/training_menu(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const training_menu_counters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"counters"],[2,[7,"/"],[2,[6,"training_menu"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/training_overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const training_overview_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"training_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pay_stubs/undo_stub(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undo_stub_admin_pay_stubs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pay_stubs"],[2,[7,"/"],[2,[6,"undo_stub"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/update_drone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_drone_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_drone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/update_entry(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_entry_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_entry"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriptions/:id/update_job(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_job_admin_subscription_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_job"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/update_line_item(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_line_item_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_line_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/jobs/:id/update_plan_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_plan_request_admin_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_plan_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/update_take_off(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_take_off_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_take_off"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/update_take_off_location(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_take_off_location_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_take_off_location"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pens/:id/upload_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_image_admin_pen_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pens"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/jobs/:id/upload_photo(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_photo_api_v1_job_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/masquerade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_masquerade_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"masquerade"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/feedlots/:id/validate_pen_boundaries_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_pen_boundaries_file_admin_feedlot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"feedlots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate_pen_boundaries_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

